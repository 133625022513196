import React, { Component } from "react"

import Layout from "../../../components/layout"
import ProductPage from "../../../components/productpage"

import image_product from '../../../images/product/k10x.jpg'
import image_product_mobile from '../../../images/product/k10x_mobile.jpg'

import substratelabels from "../../../content/substratelabels.json"

class K10xPage extends Component {

	render() {
		return (
		  <Layout mobileTitle={true} 
		  		customClass='product-title'
		  		activePath='product/drywall'
		  		pageTitle="Konstrukt&trade; Durafill&trade; K-101 Gypsum Putty and K-103 Fast-Setting Gypsum Compound">
		  	<ProductPage 
		  		pageTitle="Konstrukt&trade; Durafill&trade; K-101 Gypsum Putty and K-103 Fast-Setting Gypsum Compound"
		  		productID={"73"}
		  		image_product={image_product}
		  		image_product_mobile={image_product_mobile}
		  		surfacelist={
		  			[
		  				{
		  					"code":0,
		  					"key":"dapn",
		  					"name":substratelabels['dapn']
		  				}
		  			]
		  		}
		  		/>
		  </Layout>
		);
	}
}
export default K10xPage
